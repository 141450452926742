import * as React from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { getRequest } from '../../utils/apiMethods';
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { Button } from '@mui/material';
import DrawerAppBar from '../../components/Drawer';

const columns = [
  { id: 'brokerage', label: 'brokerage', minWidth: 170 },
  { id: 'buy_price', label: 'buy_price', minWidth: 100 },
  {
    id: 'buy_quantity',
    label: 'buy_quantity',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'buy_total_amount',
    label: 'buy_total_amount',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toLocaleString('en-US'),
  },
  {
    id: 'ce_or_pe',
    label: 'ce_or_pe',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'current_timestamp',
    label: 'current_timestamp',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'current_price',
    label: 'current_price',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'ltp',
    label: 'ltp',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'pcr_decreasing_count',
    label: 'pcr_decreasing_count',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'pcr_increasing_count',
    label: 'pcr_increasing_count',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'pcrs_to_consider_before_trading',
    label: 'pcrs_to_consider_before_trading',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'profit_or_loss',
    label: 'profit_or_loss',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'sell_amount',
    label: 'sell_amount',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'sell_amount_after_brokerage',
    label: 'sell_amount_after_brokerage',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'sell_price',
    label: 'sell_price',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'sell_quantity',
    label: 'sell_quantity',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'sell_timestamp',
    label: 'sell_timestamp',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'sell_trigger',
    label: 'sell_trigger',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'stop_loss',
    label: 'stop_loss',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'target',
    label: 'target',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
  {
    id: 'trading_symbol',
    label: 'trading_symbol',
    minWidth: 170,
    align: 'right',
    format: (value) => value.toFixed(2),
  },
];

export default function Trade() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [data, setData] = React.useState([]);


  React.useEffect(() => {

    // callApi();
    const interval = setInterval(() => {
      callApi();
    }, 1000)
    return () => {
      clearInterval(interval);
    };
  }, [])


  const callApi = async() => {
    const response = await getRequest(process.env.REACT_APP_PAPER_TRADE);
    console.log("response", response);
    if (response.length) {
        setData(response);
    }
  }

  const downloadCSV = () => {
    // Convert JSON to CSV
    const csv = Papa.unparse(data);

    // Create a Blob from the CSV
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    // Trigger the file download
    saveAs(blob, "data.csv");
  };

  return (
    <>
    <DrawerAppBar /> 
    <Paper sx={{ width: '100%',height: "100%", overflow: 'hidden', padding:"10px"}}>
    <h1>Paper Trade</h1>
    <Button variant="contained" onClick={() => downloadCSV()}>Download CSV</Button>
      <TableContainer sx={{ maxHeight: "90%" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format && typeof value === 'number'
                            ? column.format(value)
                            : value}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {/* <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      /> */}
    </Paper>
    </>
  );
}

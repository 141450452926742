import React, { useState, useEffect } from "react";
import { formatDateTime } from "../utils/Common";

const DateTimeDisplay = () => {
  const [dateTime, setDateTime] = useState("");

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDateTime(formatDateTime());
    }, 1000); // Update every second

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, []);

  return <div>{dateTime}</div>;
};

export default DateTimeDisplay;

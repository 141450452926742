
export const setDataToLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

export const getDataFromLocalStorage = (key) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

export const deleteDataFromLocalStorage = (key) => {
  localStorage.removeItem(key);
};

export const setDataToSessionStorage = (key, data) => {
  sessionStorage.setItem(key, JSON.stringify(data));
};

export const getDataFromSessionStorage = (key) => {
  const data = sessionStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

export const deleteDataFromSessionStorage = (key) => {
  sessionStorage.removeItem(key);
};

export const formatDateTime = () => {
  const date = new Date();
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
};

//
// Function to fetch holidays from Upstox API
export const fetchHolidays = async () => {
  const response = await fetch("https://api.upstox.com/v2/market/holidays", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  const data = await response.json();

  // Extract NSE trading holidays
  const nseHolidays = data.data
    .filter(
      (holiday) =>
        holiday.holiday_type === "TRADING_HOLIDAY" &&
        holiday.closed_exchanges.includes("NSE")
    )
    .map((holiday) => holiday.date);

  return nseHolidays;
};

// Check if a given date is a holiday
export const isHoliday = (date, holidays) => {
  const dateString = date.toISOString().split("T")[0];
  return holidays.includes(dateString);
};

// Adjust the expiry date if it falls on a holiday or weekend
export const adjustExpiryDate = (date, holidays) => {
  let adjustedDate = new Date(date);

  while (
    isHoliday(adjustedDate, holidays) ||
    adjustedDate.getDay() === 0 ||
    adjustedDate.getDay() === 6
  ) {
    adjustedDate.setDate(adjustedDate.getDate() - 1); // Move to the previous day
  }

  return adjustedDate;
};

// Function to calculate all Thursdays until the end of the year with holiday adjustments
export const getAllExpiryDatesUntilDecember = (holidays) => {
  let dates = [];
  let today = new Date();
  let year = today.getFullYear();
  let lastDayOfYear = new Date(year, 11, 31); // December 31st

  // Correctly find the next Thursday
  let nextThursday = new Date(today);
  nextThursday.setDate(
    nextThursday.getDate() + ((4 - nextThursday.getDay() + 7) % 7)
  ); // Get next Thursday

  while (nextThursday <= lastDayOfYear) {
    // console.log(Checking Thursday: ${nextThursday});

    // Adjust if the nextThursday is a holiday
    let expiryDate = new Date(nextThursday);
    expiryDate = adjustExpiryDate(expiryDate, holidays); // Adjust the expiry date if needed
    // console.log(Final Expiry Date: ${expiryDate});
    dates.push(expiryDate);

    // Move to the next Thursday
    nextThursday.setDate(nextThursday.getDate() + 7);
  }

  return dates;
};

// Function to format the date as "Dec 26 2024"
export const formatDate = (date) => {
  const options = { year: "numeric", month: "short", day: "numeric" };
  return date.toLocaleDateString("en-US", options);
};

// Function to generate strike prices rounded to the nearest 50-point intervals
export const generateStrikePrices = (currentPrice, step) => {
  const lowerStrike = Math.floor(currentPrice / step) * step; // Round down to nearest 50
  const upperStrike = lowerStrike + step; // Round up to nearest 50

  return [lowerStrike, upperStrike];
};

export const generateStrikePricesList = (min, max, step) => {
  let strikePrices = [];
  for (let price = min; price <= max; price += step) {
    strikePrices.push(price);
  }
  return strikePrices;
};

import * as React from "react";
import { styled } from "@mui/material/styles";

import MuiAppBar from "@mui/material/AppBar";

import styles from "../styles/dashboard.module.css";
import DateTimeDisplay from "./DtaeTimeDisplay";
import { Link } from "react-router-dom";
import { Button } from "@mui/material";
import { getDataFromLocalStorage } from "../utils/Common";
const drawerWidth = 240;

export default function Nav({ isConnected = false }) {
  const role = getDataFromLocalStorage("role");
  React.useEffect(() => {
    const unloadCallback = (event) => {
      event.preventDefault();
      event.returnValue = ""; // For Chrome
      return ""; // For other browsers
    };

    window.addEventListener("beforeunload", unloadCallback);

    return () => {
      window.removeEventListener("beforeunload", unloadCallback);
    };
  }, []);

  console.log("NAV", isConnected);
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== "open",
  })(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: ` calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  return (
    <nav className={styles.navbar}>
      <div className={styles.leftDiv}>
        <p className={styles.connectionText}>Connetion Status: </p>{" "}
        <div
          className={isConnected ? styles.greenCircle : styles.redCircle}
        ></div>
      </div>
      <div className={styles.middleDiv}>
        <DateTimeDisplay />
      </div>
      <div className={styles.rightDiv}>
        <p className={styles.connectionText}>Margin Money </p>
      </div>
      {
        role === "admin" ? (
          <div className={styles.rightDiv}>
            <Button className={styles.connectionText} onClick={() => window.location.assign("/admin-login")}>Admin Login</Button>
          </div>
        ) : null
      }

    </nav>
  );
}

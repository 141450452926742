// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LoginPage from './components/Login';
import AdminLogin from './Pages/Admin-Login/AdminLogin';
import AlphabetCanvas from './components/TPOChart';
// import MarketProfileChart from './components/NewChart';
import Dashboard from './Pages/Dashboard/Dashboard';
import MarketProfile from './Pages/MarketProfile/MarketProfile';
import Settings from './Pages/Settings/Settings';
import Trade from './Pages/Trade/Trade';
import DrawerAppBar from './components/Drawer';

function App() {
  return (
    <>
 
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/admin-login" element={<AdminLogin />} />
          <Route path="/market-profile" element={<MarketProfile />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/trade" element={<Trade />} />
        </Routes>
      </Router>
     
    </>

  );
}

export default App;

// SuccessCard.js
import React from 'react';
import { Card, CardContent, Typography, Box, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SuccessCard = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Full height to center vertically
      }}
    >
      <Card sx={{ minWidth: 300, textAlign: 'center', padding: 2 }}>
        <CardContent>
          <CheckCircleIcon color="success" sx={{ fontSize: 60, mb: 2 }} />
          <Typography variant="h5" component="div" gutterBottom>
            Access Granted!
          </Typography>
          <Typography variant="body2" color="text.secondary">
            You have successfully obtained an access token.
          </Typography>
          <Button onClick={() => window.location.assign("dashboard")}>Go to Dashboard</Button>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SuccessCard;

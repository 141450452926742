import React, { useEffect, useState } from 'react'
import { getRequest, postRequest, putRequest } from '../../utils/apiMethods';
import { Button, Input } from '@mui/material';
import Grid from '@mui/material/Grid';
import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import DrawerAppBar from "../../components/Drawer";

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    ...theme.applyStyles('dark', {
        backgroundColor: '#1A2027',
    }),
}));

export default function Settings() {
    const [settingsData, setSettingsData] = useState({});

    useEffect(() => {
        callApi();
    }, [])

    const callApi = async () => {
        const response = await getRequest(process.env.REACT_APP_CONFIGS, {});
        console.log("response", response)
        if (response.configs) {
            setSettingsData(response.configs);
        }
    }

    const handleChange = (e, item) => {
        const data = {...settingsData};
        data[item] = e.target.value;
        setSettingsData(data);
    }

    const handleSubmit = async () => {
        const reqObj = {
            configs: settingsData
        }
        const updateResponse = await putRequest(process.env.REACT_APP_CONFIGS, reqObj);
        console.log("updateResponse", updateResponse);
        if (updateResponse.message === "Configs updated") {
            window.location.reload(); 
        }
    }

    return (
        <div>
            <DrawerAppBar /> 
            <h1 style={{padding: "20px"}}>Settings</h1>
            <Box sx={{ flexGrow: 1 }} style={{marginRight: "20%", width: "80%"}}>
                {Object.keys(settingsData).map((item, index) => {
                    return (
                        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }} style={{margin: "10px", marginRight: "10px"}}>
                        <Grid item xs={2} sm={4} md={4}>
                            <Item>{item}</Item>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Item>{settingsData[item]}</Item>
                        </Grid>
                        <Grid item xs={2} sm={4} md={4}>
                            <Item>
                                <Input placeholder='Enter'  onChange={(e) => handleChange(e, item)} />
                            </Item>
                        </Grid>
                </Grid>
                    )
                })}
                <Button style={{marginLeft: "30px"}} variant="contained" onClick={() => handleSubmit()}>Save Settings</Button>
            </Box>
        </div>
    )
}

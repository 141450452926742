import React, { useEffect, useState } from 'react'
import Nav from '../../components/Nav'
import StockDetails from './StockDetails';
import styles from "../../styles/dashboard.module.css";
import "bootstrap/dist/css/bootstrap.min.css";
import DrawerAppBar from '../../components/Drawer';

export default function Dashboard() {
  const [isConnected, setIsConnected] = useState(false);
  const [niftyData, setNiftyData] = useState(
    null
  );
  const [bnData, setBnData] = useState(
    null
  );

  useEffect(() => {
    connectWebSocket()
  }, [])

  const connectWebSocket = async (token) => {
    try {
      const wsUrl = `${process.env.REACT_APP_WEBSOCKET_NIFTY_URL}`;
      const ws = new WebSocket(wsUrl);

      ws.onopen = () => {
        setIsConnected(true);
        console.log("Connected");
        ws.send("NIFTY BANK,NIFTY 50")

      };

      ws.onclose = () => {
        setIsConnected(false);
        console.log("Disconnected");
      };


      ws.onmessage = async (event) => {
        try {
          let parsedData = JSON.parse(event.data);
          if (parsedData[0].trading_symbol == "NIFTY 50") {
            // if (parsedData) {
            const obj = {
              indexFF: {
                ltpc: {
                  ltp: parsedData[0].last_price,
                  cp: parsedData[0].ohlc.close,
                },
                ohlc: parsedData[0].ohlc
              }
            }
            setNiftyData(obj.indexFF)
            console.log("WebSocket data", obj);
            // }
          }

          if (parsedData[1].trading_symbol == "NIFTY BANK") {

            // if (parsedData) {
            const obj = {
              indexFF: {
                ltpc: {
                  ltp: parsedData[1].last_price,
                  cp: parsedData[1].ohlc.close,
                },
                ohlc: parsedData[1].ohlc
              }
            }
            setBnData(obj.indexFF)
            console.log("WebSocket data", obj);
            // }



          }
        } catch (err) {
          // console.log("err", err)
        }
      };

      ws.onerror = (error) => {
        setIsConnected(false);
        console.log("WebSocket error:", error);
      };

      return () =>
      (ws.close = () => {
        setIsConnected(false);
      });
    } catch (error) {
      setIsConnected(false);
      console.log("WebSocket connection error: bantu", error.message);
    }
  };


  return (
    <div>
      <DrawerAppBar /> 
      <Nav isConnected={isConnected} />
      <div className={styles.wrapper}>
        <div className="row">
          <div className="col-3">
            <StockDetails title="Nifty" stockData={niftyData} />
          </div>
          <div className="col-3">
            <StockDetails title="Bank Nifty" stockData={bnData} />
          </div>
          <div className="col-3">
            <StockDetails title="DOW" stockData={bnData} />
          </div>
          <div className="col-3">
            <StockDetails title="FTSE" stockData={bnData} />
          </div>
        </div>
      </div>

    </div>
  )
}

import React, { useEffect, useState } from 'react';
import { Container, TextField, Button, Typography, Box, Paper } from '@mui/material';
import { postRequest } from '../utils/apiMethods';
import { getDataFromLocalStorage, setDataToLocalStorage } from '../utils/Common';
import { useNavigate} from "react-router-dom"

const Login = () => {
  const navigation = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const tokenData = getDataFromLocalStorage("user_token");

  useEffect(() => {
    if (tokenData) {
      navigation("/dashboard")
    }
  }, [])
 


  const handleLogin = async () => {
    const response = await postRequest(process.env.REACT_APP_LOGIN, {username: email, password});
    console.log("response", response);
    if (response?.error) {
      return 
    }
    if (response?.token) {
      const {token, username, role} = response
      setDataToLocalStorage("user_token", token);
      setDataToLocalStorage("username", username);
      setDataToLocalStorage("role", role);
      navigation("/dashboard");
    }
  };



  return (
    <Container maxWidth="xs" style={{ marginTop: '10%' }}>
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant="h5" align="center" gutterBottom>
          Login
        </Typography>
        <Box>
          <TextField
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            fullWidth
            margin="normal"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            onClick={() => handleLogin()}
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            style={{ marginTop: '16px' }}
          >
            Login
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default Login;

import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import styles from "../../styles/stockOverView.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const StockDetails = ({ title, stockData }) => {
  const [isHigh, setIshHigh] = useState(false);
  const [differenceData, setDifferenceData] = useState({
    diffValue: "0",
    diffPer: "0%",
  });

  // Example data for the line chart
  const data = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul"], // X-axis labels
    datasets: [
      {
        label: "Stock Price",
        data: [23, 24, 25, 24, 26, 27, 28], // Y-axis data
        fill: false,
        borderColor: "#007bff", // Line color
        tension: 0.1, // Line smoothness
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}`,
        },
      },
    },
  };

  useEffect(() => {
    if (
      stockData &&
      stockData.ohlc &&
      stockData.ltpc.ltp
    ) {
      if (stockData.ltpc.ltp > stockData.ltpc.cp) {
        setIshHigh(true);
      } else {
        setIshHigh(false);
      }
      const diff = stockData.ltpc.ltp - stockData.ltpc.cp;
      const percentageChange = (diff / stockData.ltpc.cp) * 100;

      setDifferenceData({
        diffValue: diff.toFixed(2),
        diffPer: percentageChange.toFixed(2),
      });
    } else {
      setIshHigh(false);
    }
  }, [stockData]);
  console.log("differenceData", differenceData);
  // 24,850.00
  return (
    <div className={styles.stockBox}>
      <p className={styles.stockName}>{title}</p>

      <div className="row">
        <div className="col-12">
          <div className={styles.stockBox}>
            <p className={styles.stockLive}>
              {stockData && stockData.ltpc.ltp && stockData.ltpc.ltp}
              <FontAwesomeIcon
                icon={isHigh ? faCaretUp : faCaretDown}
                style={{
                  color: isHigh ? "#03dd12" : "#ff0000",
                  height: "22px",
                  marginTop: "5px",
                }}
              />
            </p>
            <p className={isHigh ? styles.stockGrow : styles.stockDown}>
              {differenceData.diffValue}{" "}
              <span style={{ fontSize: "10px" }}>
                {differenceData.diffPer}%
              </span>
            </p>
          </div>
        </div>
      
      </div>

      <div className={styles.stockDivisions}>
        <p className={styles.txtAdv}>Advances -35</p>
        <p className={styles.txtDecline}>Declines -15</p>
        <p className={styles.txtUnChange}>Unchanged -0</p>
      </div>

      <div className={styles.stockDetails}>
        <table className="table">
          <thead>
            <tr>
              <th scope="col">P.close</th>
              <th scope="col">Open</th>
              <th scope="col">Max</th>
              <th scope="col">Min</th>
            </tr>
          </thead>
          <tbody>
            <tr className="table-info">
              <td className={styles.tabledata}>
                {stockData && stockData.ltpc.cp > 0
                  ? stockData.ltpc.cp
                  : "24500.00"}
              </td>
              <td className={styles.tabledata}>
                {stockData && stockData.ohlc
                  ? stockData.ohlc.open
                  : "24500.00"}
              </td>
              <td className={styles.tabledata}>
                {stockData && stockData.ohlc
                  ? stockData.ohlc.high
                  : "24500.00"}
              </td>
              <td className={styles.tabledata}>
                {stockData && stockData.ohlc
                  ? stockData.ohlc.low
                  : "24500.00"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default StockDetails;
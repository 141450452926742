import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import DrawerAppBar from "../../components/Drawer";
import { getRequest } from "../../utils/apiMethods";
import { saveAs } from "file-saver";
import Papa from "papaparse";
import { Button } from '@mui/material';

const MarketProfile = () => {
  const [marketData, setMArketData] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      callMArketProfileAPI();
    }, 1000)
    return () => {
      clearInterval(interval);
    };
  }, [])


  const callMArketProfileAPI = async () => {
    const response = await getRequest(process.env.REACT_APP_MARKET_PROFILE);
    if (response?.data?.length) {
      setMArketData(response);
    }
  }


  const downloadCSV = () => {
    // Convert JSON to CSV
    const csv = Papa.unparse(marketData.data);

    // Create a Blob from the CSV
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    // Trigger the file download
    saveAs(blob, "data.csv");
  };

  return (
   <>
    <DrawerAppBar /> 
    <div className="p-5">
      <h2 className="text-center">Market Profile of  {marketData?.current_timestamp}</h2>

      <Button variant="contained" onClick={() => downloadCSV()}>Download CSV</Button>
      {/* Table */}
      <table className="table m-3" border="1" cellPadding="10">
        <thead>
          <tr>
            <th>Time Frame</th>
            <th>Alphabet</th>
            <th>Open</th>
            <th>Close</th>
            <th>High</th>
            <th>Low</th>
            <th>Average</th>
            <th>VWAP</th>
            <th>VPOC</th>
          </tr>
        </thead>
        <tbody>
          {marketData?.data?.length && marketData?.data?.map((row, index) => (
            <tr key={index}>
              <td>{row.timestamp}</td>
              <td>{row.symbol}</td>
              <td>{row.open}</td>
              <td>{row.close}</td>
              <td>{row.high}</td>
              <td>{row.low}</td>
              <td>{row.avg}</td>
              <td>{row.vwap}</td>
              <td>{marketData.vpoc}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
   </> 
   
  );
};

export default MarketProfile;
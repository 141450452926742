import { getDataFromLocalStorage } from "./Common";

const fetchAPI = async (url, method, body = null, headers = {}) => {
    try {
      const token = getDataFromLocalStorage("user_token");
      if (token) {
        headers.Authorization = `${token}`
      }
      const options = {
        method,
        headers: {
          'Content-Type': 'application/json',
          ...headers,
        },
      };
  
      if (body) {
        options.body = JSON.stringify(body);
      }
  
      const response = await fetch(url, options);
      
      // Check if response is ok (status 200-299)
      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }
  
      // Parse JSON if response is successful
      return await response.json();
    } catch (error) {
      console.error('API call error:', error);
      throw error;
    }
  };
  
  // GET request
  export const getRequest = (url, headers = {}) => {
    return fetchAPI(url, 'GET', null, headers);
  };
  
  // POST request
  export const postRequest = (url, body, headers = {}) => {
    return fetchAPI(url, 'POST', body, headers);
  };
  
  // PUT request
  export const putRequest = (url, body, headers = {}) => {
    return fetchAPI(url, 'PUT', body, headers);
  };
  